.cs-pt {
  cursor: pointer;
}

.badge-link-primary:hover {
  background-color: rgb(189, 93, 56, 0.8) !important;
}

.badge-link-secondary:hover {
  background-color: #FFFFFF !important;
}

.footerTag {
  font-size: 88%;
}

.resume_ifr {
  border: none;
  width: 100%;
  height: 100vh;
}
